import React, { Component } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '~components/seo';
import PageWrapper from '~components/page-wrapper/page-wrapper.component';
import Hero from '~components/hero/hero.component';
import FeaturedCardList from '~components/featured-card-list/featured-card-list.component';
import CtaFeatured from '~components/cta-featured/cta-featured.component';

class Index extends Component {
  renderHero = () => {
    const { hero } = this.props.data.contentfulLandingPage;
    if (!hero) {
      return null;
    }
    return <Hero data={hero} isExternal={false} />;
  }

  renderCtaFeatured = () => {
    const page = get(this.props, 'data.contentfulLandingPage');
    if (!page || (!page.length && !Object.keys(page).length)) {
      return null;
    }

    const {
      ctaFeatured,
    } = page;

    if (!ctaFeatured) {
      return null;
    }

    return (
      <CtaFeatured
        {...ctaFeatured}
      />
    );
  }

  render() {
    const page = get(this.props, 'data.contentfulLandingPage');

    const { renderCtaFeatured } = this;

    return (
      <PageWrapper
        hero={this.renderHero()}
        references={page.references}
        showIsi={true}
      >
        <SEO title={page.title} />
        <FeaturedCardList cards={page.featuredCards} />
        {renderCtaFeatured()}
      </PageWrapper>
    )
  }
}

export default Index;

export const query = graphql`
  query HomePageQuery {
    contentfulLandingPage(slug: {eq: "index"}) {
      contentful_id
      slug
      title
      hero {
        heroHeadline {
          heroHeadline
        }
        bynderBackgroundImage {
          name
          src
          original
        }
      }
      featuredCards {
        title
        body {
          body
        }
        linkText
        link
        bynderImage {
          description
          name
          src
        }
      }
      ctaFeatured {
        ... on ContentfulCtaFeatured {
          title
          body {
            body
          }
          ctaText
          ctaUrl
        }
      }
      references {
        references
      }
    }
  }
`;
